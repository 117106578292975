$container: 1180px;

$All: 'all and (min-width: 0px)';
$Narrow: 'all and (min-width: 768px)';
$Normal: 'all and (min-width: 1024px)';
$WiderNormal: 'all and (min-width: 1200px)';
$Wide: 'all and (min-width: 1366px)';

@mixin clearfix {
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}

:root {
    --notification-color: #2f8600;
    --primary-color: #43c000; 
    --primary-color--hover: #2f8600;
    --secondary-color--darken: #dcdcdc;
    --font-color: #4f4f4f;
    --font-color-disabled: #00000061;
    --background-disabled: #fafafa;
    --title-after: #58cc02;
    --background-image: url("../assets/background.svg");
    --menu-image: url("../assets/menu.svg");
}
  
[data-theme="kelep"] {
    --notification-color: #BB5816;
    --primary-color: #ED8526;
    --primary-color--hover: #BB5816;
    --secondary-color--darken: #dcdcdc;
    --font-color: #4f4f4f;
    --font-color-disabled: #00000061;
    --background-disabled: #fafafa;
    --title-after: #F9AA6F;
    --background-image: url("../assets/background-kelep.svg");
    --menu-image: url("../assets/menu-kelep.svg");
}
