@import "normalize";
@import "variables";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Zilla+Slab:wght@300;500;600;700&display=swap");

html {
    transition: 0.4s ease-in;
    &::-webkit-scrollbar,
    ::-webkit-scrollbar {
        margin-top: 10px;
        width: 10px;
        cursor: default;
        &-thumb {
            background: rgba(0, 0, 0, 0.2);
            &:hover {
                background: rgba(0, 0, 0, 0.3);
            }
        }
        &-track {
            margin: 15px 0;
        }
    }
    scroll-behavior: smooth;
}

label {
    color: #4f4f4f;
}

body {
    margin: 0;
    font-family: "Inter", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: var(--background-image);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: right;
    background-size: cover;
    background-color: rgba(#f7f7f7, 1);
    overflow-x: hidden;
    overscroll-behavior-y: none;
}

.homepalyazatok {
    background-color: var(--primary-color);
    mask-image: url("../assets/homepalyazatok.svg");
    mask-repeat: no-repeat;
    mask-position: center;
    display: block;
    height: 36px;
}

.homeuser {
    background-color: var(--primary-color);
    mask-image: url("../assets/userhome.svg");
    mask-repeat: no-repeat;
    mask-position: center;
    display: block;
    height: 36px;
}

.jelen {
    background-color: var(--primary-color);
    mask-image: url("../assets/jelen.svg");
    mask-repeat: no-repeat;
    mask-position: center;
    display: block;
    height: 36px;
}

.filesvg {
    background-color: var(--primary-color);
    mask-image: url("../assets/file.svg");
    mask-repeat: no-repeat;
    mask-position: center;
    display: block;
    height: 36px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

a {
    text-decoration: underline;
    color: rgba(79, 79, 79, 1);
}

h1 {
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 0.1em;
    text-align: left;
    text-transform: uppercase;
}

h2 {
    font-size: 22px;
    margin: 30px 0 15px;
}

h3 {
    font-size: 18px;
    margin: 20px 0 10px;
}

h1,
h2,
h3 {
    font-family: "Poppins", sans-serif;
}

input[type="checkbox"] {
    appearance: none;
    border: 1px solid var(--secondary-color--darken);
    min-width: 15px;
    height: 15px;
    margin-right: 6px;
    display: inline-block;
    vertical-align: middle;
    transition: all 0.2s;
    border-radius: 2px;

    &:checked {
        background-image: url("../assets/check.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 10px;
    }

    &:focus {
        outline-offset: 0;
        outline: 0;
    }
}
.forgot-form,
.register-form,
.profile-edit,
.tender,
.login-form {
    &-wrapper {
        margin: 2rem auto;
        padding: 20px 20px;
        border-radius: 8px !important;
        box-shadow: none !important;
        @media #{$Narrow} {
            padding: 20px 60px;
            width: 29.3rem;
            margin: 3rem auto 0;
        }
    }
    &-links {
        display: flex;
        justify-content: space-between;
        margin: 20px 0;
    }
    &-button {
        display: flex;
        justify-content: center;
        margin-top: 15px;
    }
}

.register-form-wrapper {
    @media #{$Narrow} {
        padding: 20px 122px;
        width: 44rem;
    }
}
.profile-edit-wrapper {
    margin: 0;
    @media #{$Narrow} {
        padding: 20px 122px;
        width: 44rem;
    }
    .MuiCardContent-root {
        padding: 0px;
        @media #{$Narrow} {
            padding: initial;
        }
    }
    .MuiInputBase-root {
        width: 100%;
        @media #{$Narrow} {
            width: auto;
        }
    }
}
.select {
    width: 100%;
    @media #{$Narrow} {
        width: auto;
    }
}
.contract,
.tender {
    max-width: 62rem;
    &-wrapper {
        padding: 4px;
        @media #{$Narrow} {
            padding: 40px 120px;
        }
        margin: 1rem 0;
        width: auto;
    }
    &-title {
        color: var(--font-color);
        font-weight: 600;
        font-size: 24px;
        margin: 1rem 0 calc(46px - 1rem);
    }
    .MuiInputBase-root {
        width: 100%;
        @media #{$Narrow} {
            width: auto;
        }
    }
    .number {
        width: 100%;
        @media #{$Narrow} {
            width: 10.5rem !important;
        }
    }
    .file {
        @media #{$Narrow} {
            .field__value {
                flex-direction: row;
                align-items: flex-start;
                gap: 10%;
            }
            > div {
                flex-basis: 50%;
            }
            &-upload {
                display: flex;
                flex-direction: column;
                flex-basis: 50%;
                gap: 15px;
                .MuiPaper-root {
                    margin: 0;
                }
                a {
                    word-break: break-all;
                }
            }
        }
    }
}

.form-buttons {
    display: flex;
    justify-content: center;
    gap: 18px;
}

.form-links {
    display: flex;
    flex-flow: column wrap;
    margin: 2rem 0 0;
    align-items: center;
    row-gap: 2rem;
}

.hierarchical {
    display: flex;
    gap: 1.5rem;
    flex-flow: row wrap;
}

.nav {
    display: flex;
    justify-content: space-between;
    background: white;
    user-select: none;
    &-menu {
        display: flex;
        align-items: center;
        cursor: pointer;
        height: 100%;
        padding: 1.6rem;
        transition: 0.5s ease;
        &::before {
            content: " ";
            transition: 0.5s ease;
            background-image: var(--menu-image);
            background-position: center;
            background-size: 20px;
            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
        }
        &.active {
            background-color: #bdbdbd;
            &::before {
                transform: rotate(90deg);
                background-image: url("../assets/menu-white.svg");
            }
        }
    }
    &-notification {
        display: flex;
        align-items: center;
        cursor: pointer;
        height: 100%;
        padding: 0.5rem;
        transition: 0.5s ease;
        &::before {
            content: " ";
            transition: 0.5s ease;
            background-image: url("../assets/notifications.svg");
            background-position: center;
            background-size: 40px;
            width: 50px;
            height: 50px;
            background-repeat: no-repeat;
        }
        &.is-active {
            &::before {
                background-image: url("../assets/notifications-active.svg");
            }
        }
    }
    &-item {
        padding: 1rem 3rem 1rem 2rem;
        text-decoration: none;
        border-radius: 8px;
        color: #ffffff;
        cursor: pointer;
        transition: 0.2s ease;
        user-select: none;
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        column-gap: 0.7rem;
        font-size: 1rem;
        &:hover {
            background-color: var(--primary-color);
            font-weight: bold;
        }
        @media #{$Narrow} {
            margin-bottom: 5px;
            font-size: initial;
        }
    }
    &-link:last-of-type &-item {
        margin-bottom: 0px;
    }
    &-link {
        text-decoration: none;
        color: var(--font-color);
        &.active .nav-item {
            background-color: var(--primary-color);
            font-weight: bold;
        }
    }
    &-logo {
        margin-right: 1rem;
        width: 45px;
        &-title {
            text-transform: uppercase;
            font-family: Zilla Slab;
            font-weight: bold;
            font-size: 2rem;
        }
        &-wrapper {
            display: flex;
            flex-direction: column;
        }
    }
    &-left {
        display: flex;
        align-items: center;
        margin-left: 1.5rem;
        padding: 1rem 0;
        text-decoration: none;
        gap: 0.5rem;
    }
    &-right {
        display: flex;
    }
    &-popper {
        background-color: #4f4f4f;
        padding: 1rem !important;
        width: 100vw;
        &:not(.nouser) {
            height: 100% !important;
            @media #{$Normal} {
                height: auto !important;
            }
        }
        margin-left: -5px;
        @media #{$Narrow} {
            border-radius: 8px 0px 0px 0px;
            width: auto;
            margin: 0;
        }
        &.nouser {
            @media #{$Narrow} {
                border-radius: 8px 0px 8px 8px;
            }
        }
    }
    .user {
        background-color: var(--primary-color);
        display: flex;
        padding: 1rem 2rem;
        color: white;
        align-items: center;
        margin-left: -5px;
        &-name {
            font-size: 13px;
        }
        &-picture {
            margin-right: 15px;
            img {
                height: 40px;
                width: 40px;
                vertical-align: middle;
                border-radius: 50%;
            }
        }
        span {
            color: rgba(255, 255, 255, 0.5);
        }
        @media #{$Narrow} {
            padding: 1rem 2rem;
            margin: 0;
            border-radius: 0 0 8px 8px;
        }
    }
    .paper {
        border-radius: 0px;
        box-shadow: none;
        @media #{$Narrow} {
            padding: 0px;
            border-radius: 8px;
        }
        &-list {
            padding: 0;
            &.nav-user {
                height: calc(var(--vh, 1vh) * 100 - 9rem);
                @media #{$Normal} {
                    height: auto;
                }
            }
            @media #{$Narrow} {
                border-radius: 8px;
            }
        }
    }
}

.content {
    max-width: 1200px;
    flex: 1;
    padding: 0 15px;
    @media #{$Narrow} {
        padding: 0 15px;
        margin: 0 auto;
        padding: 0;
    }
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 99vh;
}

.footer {
    display: flex;
    flex-direction: column;
    justify-self: center;
    padding: 2rem;
    text-align: center;
    &-images {
        display: flex;
        justify-content: center;
        column-gap: 0.6rem;
    }
    &-text {
        margin-top: 1rem;
        color: rgb(170, 170, 170);
    }
}

.homepage {
    .breki-title {
        margin-top: 3.3rem;
    }
    &-item,
    &-items {
        display: flex;
        justify-content: center;
        column-gap: 2rem;
        row-gap: 1rem;
        flex-flow: row wrap;
    }
    &-item {
        flex-direction: column;
        text-align: center;
        &-image {
            height: 20rem;
        }
    }
}

.tender-teaser {
    display: flex;
    align-items: stretch;
    gap: 2rem;
    flex-flow: row wrap;
    @media #{$Narrow} {
        flex-flow: row;
    }
    &__left {
        flex: 33%;
    }
    &__right {
        flex: 66%;
        text-align: left;
    }
    &__info {
        color: #999999;
        margin-top: 5px;
        font-style: italic;
        font-size: 0.9rem;
    }
    &__body {
        font-size: 0.93rem;
    }
    &-buttons {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        margin-top: 1.5rem;
        justify-content: center;
        @media #{$Narrow} {
            justify-content: left;
        }
        flex-flow: row wrap;
        a,
        button {
            width: 100%;
            font-size: 15px;
            @media #{$Narrow} {
                font-size: 16px;
            }
        }
        @media #{$Narrow} {
            a,
            button {
                width: auto;
            }
        }
        a {
            text-decoration: none;
        }
    }
    &-print {
        display: flex;
        align-items: center;
    }
    &__title {
        margin: 0 0 1.5rem;
    }
    &__body {
        p {
            margin: 0;
            font-size: 16px;
            font-family: Inter;
            color: var(--font-color);
        }
    }
    &__image {
        width: 100%;
        height: 8rem;
        background-size: cover;
        background-position: center;
        border-radius: 4px;
        @media #{$Narrow} {
            height: 100%;
        }
    }
}

.field {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin: 0 0 1.56rem;
    &__label {
        font-size: 16px;
        color: rgba(79, 79, 79, 1);
        line-height: 19px;
    }
    &__value {
        .MuiFormControl-root {
            width: 100%;
        }
    }
    .prefix {
        display: flex;
        gap: 10px;
        align-items: baseline;
    }
    &__helptext {
        margin: 0.1rem 0;
        color: rgb(177, 177, 177);
        @media #{$Narrow} {
            text-align: justify;
        }
    }
    &__error {
        color: red;
    }
}

.file .field__value {
    display: flex;
    flex-direction: column;
}

.boolean .field {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    column-gap: 1rem;
}

.boolean .field__label {
    flex: 0 0 93%;
    order: 2;
}

.primary-button {
    align-self: center;
}

form {
    display: flex;
    flex-direction: column;
}

.profile {
    @media #{$Narrow} {
        width: 44rem;
    }
    &__header {
        display: flex;
        gap: 30px;
        flex-flow: row wrap;
        @media #{$Narrow} {
            flex-flow: row;
        }
    }
    &-name {
        color: var(--font-color);
        font-size: 24px;
        font-family: Inter;
        font-weight: 600;
    }
    &__image {
        img {
            background-size: cover;
            @media #{$Narrow} {
                max-width: 240px;
            }
            border-radius: 4px;
        }
    }
    &__infos {
        flex: calc(100% - 200px);
        text-align: left;
    }
    &__info {
        display: flex;
        flex-direction: row;
        gap: 60px;
    }
    .field {
        flex-direction: column;
        @media #{$Narrow} {
            flex-direction: row;
        }
        &__label {
            min-width: 50%;
        }
    }
    .card {
        padding: 20px;
        @media #{$Narrow} {
            padding: 35px;
        }
    }
}
.tender-status,
.card {
    color: #4f4f4f;
    font-size: 16px;
    background: #ffffff;
    border-radius: 8px;
    padding: 20px;
    @media #{$Narrow} {
        padding: 35px;
    }
    margin-bottom: 1.5rem;
    box-shadow: none !important;
    &-title {
        font-size: 24px;
        font-weight: bold;
        color: black;
        &-sub {
            font-size: 14px;
            margin-top: 0.2rem;
        }
    }
    &-underline {
        font-size: 22px;
        font-family: Inter;
        font-weight: bold;
        color: black;
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        &::after {
            content: " ";
            background-color: var(--title-after);
            height: 2px;
            margin: 1.5rem 0 0.3rem;
            width: 5rem;
        }
    }
    &.center {
        text-align: center;
        @media #{$Narrow} {
            max-width: 16rem;
        }
        padding: 24px 29px;
        margin-bottom: 0;
        border-radius: 8px;
    }
    &-text {
        font-size: 16px;
        color: rgba(130, 130, 130, 1);
    }
    &-link {
        text-decoration: none;
        width: 100%;
        @media #{$Narrow} {
            width: auto;
        }
    }
}

.privacy-policy-paragraph {
    line-height: 1.5;
}

.privacy-policy-list {
    li {
        line-height: 1.5;
    }
}

.tender-status {
    background: rgba(255, 255, 255, 1);
    &-checkboxes {
        margin: 2rem 0 0 1rem;
        line-height: 1.5rem;
    }
    @media #{$Narrow} {
        width: 44rem;
    }
    &-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
.file-upload {
    display: flex;
    flex-direction: column;
}

.required {
    color: red;
}
h3.breki-title,
h2.breki-title {
    font-size: 28px;
    color: var(--font-color);
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    font-weight: normal;
    font-weight: 300;
    line-height: 48px;
    margin: 30px 0 calc(46px - 1rem);
    text-align: center;
    &::after {
        content: " ";
        background-color: var(--title-after);
        height: 2px;
        margin: 1rem 0;
        width: 5rem;
    }
    @media #{$Narrow} {
        font-size: 32px;
    }
}

h3.breki-title {
    font-size: 28px;
    margin-top: 2.5rem !important;
}
.alert-file-upload,
.alert {
    margin-top: 1.5rem;
    .MuiAlert-icon {
        justify-content: center;
        max-width: 200px;
    }
    .MuiAlert-message {
        word-break: break-word;
    }
    @media #{$Narrow} {
        flex-direction: row;
    }
}

.alert-file-upload {
    flex-direction: column;
    @media #{$Narrow} {
        flex-direction: row;
    }
}

.edit {
    text-align: end;
    svg {
        color: var(--primary-color);
    }
}
.print {
    align-self: center;
    svg {
        color: var(--primary-color);
    }
}

.hidden {
    display: none;
}

.teasers {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    &-card {
        box-shadow: none !important;
        padding: 0 0.3rem;
        @media #{$Narrow} {
            width: 44rem;
        }
    }
}
.report {
    text-align: center;
    padding: 2rem 10px;
    background-color: var(--notification-color);
    border-radius: 8px;
    color: white;
    margin: calc(46px - 1rem) 0;
    p {
        line-height: 1.5rem;
    }

    div > p {
        max-width: 44em;
        padding: 5px;
    }
}

.big-alert {
    margin-bottom: 1.5rem;
}

.accordion {
    .select {
        display: flex;
        max-width: 44rem;
    }
    &-wrapper {
        margin-bottom: 2rem;
        &--error {
            .accordion {
                border: 2px solid red;
                box-shadow: none;
            }
        }
    }
}

.select-paper {
    max-width: 44rem;
    @media #{$Narrow} {
        max-width: 100%;
    }
}

.dokumentumok {
    display: flex;
    ul {
        display: flex;
        gap: 10px;
        flex-flow: column wrap;
        list-style: none;
    }
}

[class*="name"] input {
    text-transform: capitalize;
}

.outline-none {
    outline: none;
}

.mm-chat-window {
    z-index: 1 !important;
}

.contract-status-info {
    text-align: center;
    padding: 2rem 10px;
    background-color: var(--notification-color);
    border-radius: 8px;
    color: white;
    margin: calc(46px - 1rem) 0;
    width: 100%;
    p {
        a {
            color: white;
            text-decoration: underline;
            margin: 0 4px;
        }
    }
}

.notifications {
    max-width: 520px;
    margin: 0 auto;
    position: relative;
    &-expired {
        .notification {
            background-color: #aaa;
        }
    }
}

.notification {
    text-align: left;
    padding: 10px;
    background-color: var(--notification-color);
    border-radius: 8px;
    color: white;
    font-size: 14px;
    margin: calc(46px - 1rem) 0;
    width: 100%;
    @media #{$Narrow} {
        min-width: 520px;
    }
    p {
        line-height: 1.5rem;
        margin: 0;
    }
}

.sticky-notifications {
    max-width: 520px;
    margin: 0 auto;
}

.notifications-count {
    position: absolute;
    bottom: 17px;
    right: 10px;
    &__number {
        display: none;
        background-color: var(--notification-color);
        color: white;
        border-radius: 50%;
        font-size: 12px;
        padding: 2px 6px;

        &:last-child {
            display: inline-block;
        }
    }
}

.autocomplete--disabled {
    .MuiInputBase-root {
        background-color: var(--background-disabled);
        color: var(--font-color-disabled);
        cursor: default;
    }
    &:hover {
        border-color: var(--font-color-disabled);
    }
}
